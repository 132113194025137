import { motion, useAnimation } from "framer-motion"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
type PerkProps = {
  img: string;
  alt: string;
  title: any;
  content: string;
  large?: boolean
}


const Perk = ({ img, alt, title, content, large }: PerkProps) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <Box
      large={large}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 },
      }}
      transition={{ ease: "easeOut", duration: 1.25, delay: 0.35 }}
    >
      <PerkWrapper large={large} className={`perk`}>
        <img src={img} alt={alt} />
        <div className="perk__label">
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      </PerkWrapper>
    </Box>
  )
}
const Box = styled<any>(motion.div)`
  z-index: ${({ large }) => (large ? "1" : "0")} !important;
`

const PerkWrapper = styled.article<any>`
  @media (min-width: 768px) {
    img {
      transform: ${({ large }) => (large ? "scale(1.5)" : "")} !important;
    }
    .perk__label {
      margin: 150px 0 0 0 !important;
      min-height: 235px !important;
    }
  }

  padding: 1rem;
  max-width: 260px;
  margin: 0 auto;
  color: black;
  img {
    box-shadow: 0px 2px 7px rgb(0 0 0);
    background: #2b415cc9;
    box-sizing: border-box;
    width: 100%;
    transform: scale(1);
    border-radius: 30px;
    padding: 5px;
  }

  .perk__label {
    min-height: 225px;
    backdrop-filter: saturate(150%) blur(5px);
    background: rgb(253 253 253 / 60%);
    border-radius: 30px;
    padding: 1rem 0.75rem;
    margin: 10px 0 0 0; 
  }
`

export default Perk
