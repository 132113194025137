import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import styled from "styled-components";

type TextBlockImgProps = {
	children: any;
	subtitle?: string;
	id?: string;
};

const TextBlockImg = ({ children, subtitle }: TextBlockImgProps) => {
	const { mobileImage, otherImage } = useStaticQuery(
		graphql`
			query {
				mobileImage: file(
					relativePath: { eq: "pexels-ella-olsson-1640777.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 768, quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				otherImage: file(
					relativePath: { eq: "pexels-mariana-kurnyk-1775043.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 2000, quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	const sources = [
		mobileImage.childImageSharp.fluid,
		{
			...otherImage.childImageSharp.fluid,
			media: `(min-width: 768px)`,
		},
	];
	return (
		<BackgroundImage id="perks" Tag="section" fluid={sources}>
			<TextBlockImgWrapper>
				<div className="content-container">
					<h2>
						TOOLS FOR <span>S</span>UCCESS
					</h2>
					<p>{subtitle}</p>
					{children}
				</div>
			</TextBlockImgWrapper>
		</BackgroundImage>
	);
};

const TextBlockImgWrapper = styled.section`
	color: black;
	text-align: center;
	padding: 25px 30px;

	.content-container {
		max-width: 500px;
		padding: 20px 0px;

		@media (min-width: 768px) {
			max-width: 768px;
		}
		@media (min-width: 768px) {
			padding: 100px 0 !important;
			> p {
				margin-bottom: 160px !important;
			}

			h2 {
				font-size: 3rem !important;
			}
		}
		@media (min-width: 1200px) {
			max-width: 900px;
		}
		h2 {
			-webkit-text-stroke: 0.2rem black;
			font-weight: 900;
			font-size: 2rem;
			// background: -webkit-linear-gradient(45deg, #00cc99, #00cc99, #3f7cac);
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
			span {
				color: var(--primary-color);
				-webkit-text-stroke: 0.2rem var(--primary-color);
			}
		}

		> p {
			margin-bottom: 50px;
		}
	}
`;

export default TextBlockImg;
