import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import * as React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import Logo from "../../static/assets/branding_title.svg";
import Button from "../components/Button/button";
import Devices from "../components/Devices/devices";
import Perk from "../components/Perk/perk";
import SEO from "../components/seo";
import Stage from "../components/Stage/stage";
import TextBlock from "../components/TextBlock/textBlock";
import TextBlockImg from "../components/TextBlockImg/textBlockImg";
//@ts-ignore
import perk2Img from "../images/07.02.03.discover.trending.png";
//@ts-ignore
import perk1Img from "../images/09.05.01.createRecipe.steps.png";
//@ts-ignore
import perk3Img from "../images/10.01.01.weekly-planner.png";
import AStore from "../../static/assets/astore-dark.svg";
import GStore from "../../static/assets/gstore-dark.svg";

const IndexPage: React.FC = () => {
	const { mobileImage, otherImage } = useStaticQuery(
		graphql`
			query {
				mobileImage: file(
					relativePath: { eq: "pexels-ella-olsson-1640774.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 768, quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				otherImage: file(
					relativePath: { eq: "pexels-ella-olsson-1640774-ls.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 2000, quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	const sources = [
		mobileImage.childImageSharp.fluid,
		{
			...otherImage.childImageSharp.fluid,
			media: `(min-width: 1200px)`,
		},
	];
	const variants = {
		visible: { opacity: 1, y: 0 },
		hidden: { opacity: 0, y: 20 },
		visibleOO: { opacity: 1 },
		hiddenOO: { opacity: 0 },
	};
	return (
		<React.Fragment>
			<SEO title="KitchenShelf - Cooking made easy" />
			<IndexStyles>
				<BackgroundImage Tag="section" className="hero-image" fluid={sources}>
					<div className="hero-content">
						<div className="title">
							<motion.div
								initial="hidden"
								animate="visible"
								variants={variants}
								transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
							>
								<Logo />
							</motion.div>

							<motion.p
								initial="hidden"
								animate="visible"
								variants={variants}
								transition={{ ease: "easeOut", duration: 0.8, delay: 1.5 }}
							>
								A feed that feeds <span>you</span>
							</motion.p>
							<motion.div
								initial="hiddenOO"
								animate="visibleOO"
								variants={variants}
								transition={{ ease: "easeOut", duration: 0.8, delay: 2.5 }}
							>
								<Link to="about" smooth={true} duration={500}>
									<span className="sr-only">Jump to about</span>
									<Button cta="Learn More" label="Banner Learn More" />
								</Link>
							</motion.div>
							<motion.div
								className="store-links"
								initial="hidden"
								animate="visible"
								variants={variants}
								transition={{ ease: "easeOut", duration: 0.8, delay: 3 }}
							>
								<a
									href="https://apps.apple.com/us/app/kitchenshelf/id1615979230"
									target="_blank"
									rel="noopener noreferrer"
								>
									<AStore />
								</a>
								<a
									href="https://play.google.com/store/apps/details?id=uk.co.kitchenshelf.app.twa&gl=GB&utm_source=landing"
									target="_blank"
									rel="noopener noreferrer"
								>
									<GStore />
								</a>
							</motion.div>
						</div>
					</div>
				</BackgroundImage>
			</IndexStyles>

			<TextBlock
				id="about"
				title="Finding and organising new recipes is never easy, but KitchenShelf is here to help."
				paragraph="KitchenShelf truly is a feed that feeds you.  Follow chefs, create and share private cookbooks and watch as your personalised feed gets populated with the recipes you care about..."
			>
				<Link to="perks" smooth={true} duration={500}>
					<Button label="Tell Me More" cta="Tell Me More!" />
				</Link>
			</TextBlock>
			<TextBlockImg id="perks">
				<div className="flex-container trio-block">
					<Perk
						img={perk1Img}
						alt="Create"
						title="Create"
						content="Add your own recipes with our user friendly drag and drop flow."
					/>
					<Perk
						img={perk2Img}
						alt="Search"
						title="Search"
						content="Quickly find your own recipes and discover new ones to cook."
						large={true}
					/>
					<Perk
						img={perk3Img}
						alt="Plan"
						title="Plan"
						content="Stop last minute panics about what you are going to eat."
					/>
				</div>
			</TextBlockImg>
			<Devices
				title="Sync your recipes across all your devices."
				para="Doesn't matter if you are searching for new recipes on the bus, planning your meals in the living room or in the kitchen cooking with your tablet. We have you covered."
			>
				<Stage></Stage>
			</Devices>
		</React.Fragment>
	);
};
const IndexStyles = styled.section`
	background-color: #dbecf2;
	.hero-image {
		height: 100vh;
		color: #fff;
		&:before,
		:after {
			background-position: right !important;
		}

		@media (min-width: 1200px) {
			&:before,
			:after {
				background-position: center !important;
			}
		}

		.hero-content {
			display: grid;
			align-items: center;
			color: black;
			text-align: center;
			height: 100%;
			width: 100%;

			padding: 0 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;

			.title {
				position: relative;
				display: grid;
				justify-items: center;
				.brand_title {
					width: 300px;
				}

				@media (min-width: 768px) {
					max-width: 800px;
					.brand_title {
						width: 650px;
					}
				}

				@media (min-width: 1200px) {
					max-width: 1000px;
					.brand_title {
						width: 1000px;
					}
				}

				p {
					color: black;
					font-size: 1.5rem;
					margin: 5px 0 40px 0;
				}

				.store-links {
					display: grid;
					gap: 10px;
					grid-auto-flow: rows;
					margin: 20px 0 0 0;

					a {
						display: grid;
						justify-content: center;
					}

					@media (min-width: 768px) {
						max-width: 800px;
						grid-auto-flow: column;
					}
				}
			}
		}
	}
`;

export default IndexPage;
