import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const Stage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "stage.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Img Tag="section" fluid={data.file.childImageSharp.fluid}></Img>
  )
}

export default Stage
