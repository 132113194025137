import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
type TextBlockProps = {
	title: string;
	paragraph: string;
	children: any;
	id: string;
};

const TextBlock = ({ title, paragraph, children, id }: TextBlockProps) => {
	const controls = useAnimation();
	const [ref, inView] = useInView({
		threshold: 0.25,
	});

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);
	return (
		<TextBlockWrapper id={id}>
			<div className="content-container">
				<motion.div
					ref={ref}
					animate={controls}
					initial="hidden"
					variants={{
						visible: { opacity: 1, y: 0 },
						hidden: { opacity: 0, y: 25 },
					}}
					transition={{ ease: "easeOut", duration: 1.25, delay: 0.35 }}
				>
					<h2>{title}</h2>
					<p>{paragraph}</p>
					{children}
				</motion.div>
			</div>
		</TextBlockWrapper>
	);
};

const TextBlockWrapper = styled.section`
	background: #fefaf8;
	color: #fff;
	text-align: left;
	padding: 60px 20px;

	@media (min-width: 768px) {
		padding: 80px 30px;
		text-align: center;
	}

	h2 {
		margin: 0;

		color: #ee00b3;
		background: -webkit-linear-gradient(45deg, #00cc99, #00cc99, #3f7cac);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	p {
		color: black;
		margin-bottom: 40px;
	}
`;

export default TextBlock;
