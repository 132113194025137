import React from "react";
import styled from "styled-components";
import Button from "../../components/Button/button";
import AStore from "../../../static/assets/astore-dark.svg";
import GStore from "../../../static/assets/gstore-dark.svg";

type DevicesProps = {
	title: string;
	para: string;
	children: any;
};

const Devices = ({ title, para, children }: DevicesProps) => {
	return (
		<PackagesWrapper>
			<div className="content-container">
				<div className="image">{children}</div>
				<div className="text-area">
					<h2>{title}</h2>
					<p>{para}</p>
				</div>
				<Button
					anchor="true"
					href="https://app.kitchenshelf.co.uk/"
					label="Web sign in"
					cta="Web sign in"
				/>
				<div className="store-links">
					<a
						href="https://apps.apple.com/us/app/kitchenshelf/id1615979230"
						target="_blank"
						rel="noopener noreferrer"
					>
						<AStore />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=uk.co.kitchenshelf.app.twa&gl=GB&utm_source=landing"
						target="_blank"
						rel="noopener noreferrer"
					>
						<GStore />
					</a>
				</div>
			</div>
		</PackagesWrapper>
	);
};

const PackagesWrapper = styled.section`
	position: relative;
	display: grid;
	justify-items: center;
	text-align: center;
	background-color: #fefaf8;
	color: black;
	padding: 10px 30px 100px 30px;

	.image {
		width: 100%;
	}

	h2 {
		color: #ee00b3;
		background: -webkit-linear-gradient(45deg, #00cc99, #00cc99, #3f7cac);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.store-links {
		display: grid;
		gap: 10px;
		grid-auto-flow: rows;
		margin: 20px 0 0 0;

		a {
			display: grid;
			justify-content: center;
		}

		@media (min-width: 768px) {
			max-width: 800px;
			grid-auto-flow: column;
		}
	}
`;

export default Devices;
